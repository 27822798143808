import "./PingTextArea.scss";

type PingTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const PingTextarea = (props: PingTextareaProps) => {
  return (
    <textarea
      className="PingTextarea PingTextarea--Small PingTextarea--Rounded"
      {...props}
    />
  );
};

export { PingTextarea };
