import { FC } from "react";

import {
  PingSelectInput,
  PingTextarea,
  PingTextInput,
  US_STATES,
} from "@repo/ping-react-js";

import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "src/features/submission-dashboard/PVFormsLayouts";
import { SovDataType } from "ts-types/DataTypes";

import "./PVInsuredInformationGrid.scss";

type PVInsuredInformationGridProps = {
  selectedItem: SovDataType;
};

export const PVInsuredInformationGrid: FC<PVInsuredInformationGridProps> = ({
  selectedItem,
}) => {
  return (
    <PVInputsColumnsGrid>
      <PVInputsColumn title="Address">
        <label htmlFor="insuredName">Insured name</label>
        <PingTextInput
          id="insuredName"
          name="insuredName"
          defaultValue={selectedItem.insured_name ?? ""}
        />

        <label htmlFor="street">Street</label>
        <PingTextInput
          id="street"
          name="street"
          defaultValue={selectedItem.insured_street ?? ""}
        />

        <label htmlFor="street2">Street 2</label>
        <PingTextInput
          id="street2"
          name="street2"
          defaultValue={selectedItem.insured_street2 ?? ""}
        />

        <label htmlFor="city">City</label>
        <PingTextInput
          id="city"
          name="city"
          defaultValue={selectedItem.insured_city ?? ""}
        />

        <label htmlFor="state">State</label>
        <PingSelectInput
          options={[{ label: "Select State", value: "" }].concat(
            US_STATES.map((s) => ({ label: s.label, value: s.label }))
          )}
          id="state"
          name="state"
          defaultValue={selectedItem.insured_state ?? ""}
        />

        <label htmlFor="zip">ZIP</label>
        <PingTextInput
          id="zip"
          name="zip"
          defaultValue={selectedItem.insured_zip ?? ""}
        />

        <label htmlFor="country">Country</label>
        <PingSelectInput
          options={[
            { label: "Select country", value: "" },
            { label: "United States of America", value: "usa" },
            { label: "United Kingdom", value: "uk" },
            { label: "India", value: "in" },
          ]}
          id="country"
          name="country"
          defaultValue={selectedItem.insured_country ?? ""}
        />
      </PVInputsColumn>

      <PVInputsColumn title="Other">
        <label htmlFor="homeState">Home state</label>
        <PingSelectInput
          options={[{ label: "Select home state", value: "" }].concat(
            US_STATES.map((s) => ({ label: s.label, value: s.label }))
          )}
          id="homeState"
          name="homeState"
          defaultValue={selectedItem.home_state ?? ""}
        />

        <label htmlFor="fein">FEIN</label>
        <PingTextInput
          id="fein"
          name="fein"
          defaultValue={selectedItem.insured_fein ?? ""}
        />

        <label htmlFor="website">Website</label>
        <PingTextInput
          id="website"
          name="website"
          defaultValue={selectedItem.client_website ?? ""}
        />

        <label htmlFor="businessDescription">Business description</label>
        <PingTextarea
          id="businessDescription"
          name="businessDescription"
          defaultValue={selectedItem.insured_business_description ?? ""}
        />
      </PVInputsColumn>
    </PVInputsColumnsGrid>
  );
};
