import { FC, useCallback, useEffect, useState } from "react";

import { PVLabelValueEditor } from "features/submission-dashboard/PVLabelValueEditor";
import { SovDataType } from "ts-types/DataTypes";

import "./PVOverviewGrid.scss";
import { useUpdateSubmissionTriageMutation } from "src/services/pvSlice";
import { formatCurrency } from "src/utils/formatters";
import { formatShortDate, formatShortDateTime } from "@repo/ping-react-js";
import { differenceInDays, isValid, parseISO } from "date-fns";

type PVOverviewGridProps = {
  selectedItem: SovDataType;
};

function daysBetween(
  date1: Date | string | null,
  date2: Date | string | null
): number | null {
  // Convert strings to Dates if necessary and validate the inputs
  if (typeof date1 === "string") {
    date1 = parseISO(date1);
  }
  if (typeof date2 === "string") {
    date2 = parseISO(date2);
  }
  if (!isValid(date1) || !isValid(date2)) {
    console.warn("Invalid dates provided:", { date1, date2 });
    return null;
  }

  // Calculate and return the absolute difference in days
  return Math.abs(differenceInDays(date1, date2));
}

export const PVOverviewGrid: FC<PVOverviewGridProps> = ({ selectedItem }) => {
  const [updateSubmission] = useUpdateSubmissionTriageMutation();

  const onBlur = useCallback(
    (fieldName: string, newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          [fieldName]: newContent,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  const days_until_inception =
    daysBetween(new Date(), selectedItem?.inception_date) || "n/a";
  const days_received_in_advance = daysBetween(
    selectedItem?.created_time,
    selectedItem?.inception_date
  );

  let sender = "n/a";
  if (selectedItem?.source__source_type == "A") {
    sender = "API";
  } else if (selectedItem?.source__source_type == "E") {
    if (selectedItem?.from_email) {
      sender = selectedItem.from_email;
    }
  }

  return (
    <div className="PVOverviewGrid">
      <PVLabelValueEditor
        label="Inception Date"
        value={formatShortDate(selectedItem.inception_date) || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("inception_date", newContent)}
      />

      <PVLabelValueEditor
        label="Expiration Date"
        value={formatShortDate(selectedItem.expiration_date) || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("expiration_date", newContent)}
      />

      <PVLabelValueEditor
        label="Days to Inception (DTI)"
        value={days_until_inception}
      />

      <PVLabelValueEditor
        label="Date Received"
        value={formatShortDateTime(selectedItem.created_time)}
      />

      <PVLabelValueEditor
        label="Days Received Pre-Inception"
        value={days_received_in_advance}
      />

      <PVLabelValueEditor label="Sender" value={sender} />
      <PVLabelValueEditor
        label="Broker"
        value={selectedItem.broker_name || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_name", newContent)}
      />

      <PVLabelValueEditor
        label="Broker Office"
        value={"not implemented yet"}
        // isEditable={true}
        // onChange={(newContent) => onBlur("broker_office", newContent)}
      />

      {/* <PVLabelValueEditor
        label="Sender Email"
        value={selectedItem.from_email || "Unknown Sender"}
        isEditable={false}
        onChange={(newContent) => onBlur("from_email", newContent)}
      /> */}

      <PVLabelValueEditor
        label="Client Reference ID"
        value={selectedItem.client_ref || "n/a"}
        isEditable={true}
        onChange={(newContent) => onBlur("client_ref", newContent)}
      />

      <div>
        Total Insured Value:{" "}
        {formatCurrency(selectedItem.limits_total_limit) || "n/a"}
      </div>
      <div>Buildings: {selectedItem.num_buildings || "n/a"}</div>
    </div>
  );
};
