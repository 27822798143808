import { FC } from "react";
import cx from "classnames";

import {
  PingRichTextInputField,
  TextSchemaOptions,
} from "features/submission-dashboard/PingRichTextInputField";

import "./PVLabelValueEditor.scss";

const TEXT_SCHEMA_OPTIONS: TextSchemaOptions = {
  type: "text",
};

type PVLabelValueEditorProps = {
  label: string;
  value: string | number;
  isEditable?: boolean;
  onChange?: (value: string) => void;
};

export const PVLabelValueEditor: FC<PVLabelValueEditorProps> = ({
  label,
  value,
  isEditable = false,
  onChange = null,
}) => {
  const appliedClasses = cx("PVLabelValueEditor", {
    "PVLabelValueEditor--IsEditable": isEditable,
  });

  return (
    <div className={appliedClasses}>
      <div className="PVLabelValueEditor__Label">{label}</div>
      <div className="PVLabelValueEditor__Value">
        {isEditable ? (
          <PingRichTextInputField
            schemaOptions={TEXT_SCHEMA_OPTIONS}
            initialContent={value}
            onBlur={(newContent) => onChange(newContent)}
            className="PVLabelValueEditor__Value__Editor"
          />
        ) : (
          value
        )}
      </div>
    </div>
  );
};
