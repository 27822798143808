import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
  Children,
  cloneElement,
  useMemo,
  ReactElement,
  isValidElement,
  useCallback,
  PropsWithChildren,
  HTMLProps,
  ComponentProps,
} from "react";
import throttle from "lodash/throttle";
import slugify from "slugify";
import cx from "classnames";

import { PVSectionHeading } from "features/submission-dashboard/PVHeadings";

import "./PVScrollSpy.scss";

const THROTTLE_TIMEOUT_MS = 250;

const SECTION_CLASSNAME = "PVScrollSpySectionWrapperSelector";

type PVScrollSpyContextType = {
  elements: Element[];
  setElements(elements: Element[]): void;

  /** Maps section IDs to titles. */
  elementIds: Record<string, string>;
  setElementIds(elementIds: Record<string, string>): void;

  currentIntersectingElementIndex: number;
  offsetPx?: number;
};

/**
 * Holds shared data for all the scroll spy components.
 */
const PVScrollSpyContext = createContext<PVScrollSpyContextType | null>(null);

/**
 * Provides access to the scroll spy context.
 */
const useScrollspyContext = () => {
  const context = useContext(PVScrollSpyContext);
  if (!context) {
    throw new Error(
      "useScrollspyContext must be used within a PVScrollSpyProvider"
    );
  }
  return context;
};

type PVScrollSpyProviderProps = {
  offsetPx?: number;
  children?: ReactNode;
};

/**
 * Wrapper component that provides access to scrollspy state and updates that
 * state as the DOM changes.
 */
export const PVScrollSpyProvider: FC<PVScrollSpyProviderProps> = ({
  offsetPx = 0,
  children,
}) => {
  const [sectionElements, setSectionElements] = useState<Element[]>([]);
  const [sectionElementIds, setSectionElementIds] = useState<
    Record<string, string>
  >({});
  const [currentIntersectingElementIndex, setCurrentIntersectingElementIndex] =
    useState(0);

  const rootRef = useRef<HTMLElement>(null);

  const rootEl = useMemo(() => {
    if (Children.count(children) !== 1) {
      throw new Error(
        "PVScrollSpyProvider must have exactly one child element"
      );
    }
    return Children.only(children) as ReactElement;
  }, [children]);

  const onScroll = useMemo(
    () =>
      throttle(() => {
        if (!rootRef.current) {
          return;
        }

        // TODO: this is very slow, but we can make it faster by caching element
        // rects.
        const idx = getFirstVisibleChildIndex(
          rootRef.current,
          sectionElements,
          offsetPx
        );

        // Keep the previous value if nothing is intersecting currently.
        if (idx !== -1) {
          setCurrentIntersectingElementIndex(idx);
        }
      }, THROTTLE_TIMEOUT_MS),
    [sectionElements, offsetPx]
  );

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const root = rootRef.current;
    root.addEventListener("scroll", onScroll);
    onScroll();

    return () => {
      root.removeEventListener("scroll", onScroll);
    };
  }, [offsetPx, rootRef, onScroll]);

  return (
    <PVScrollSpyContext.Provider
      value={{
        elements: sectionElements,
        setElements: setSectionElements,
        elementIds: sectionElementIds,
        setElementIds: setSectionElementIds,
        currentIntersectingElementIndex,
        offsetPx,
      }}
    >
      {cloneElement(rootEl, { ref: rootRef })}
    </PVScrollSpyContext.Provider>
  );
};

/**
 * Renders a tab bar giving access to all the sections in the scrollspy and
 * reflects which element is currently in the view.
 */
export const PVScrollSpyTabBar: FC = () => {
  const { elementIds, currentIntersectingElementIndex } = useScrollspyContext();

  const scrollContainerRef = useRef<HTMLUListElement | null>(null);
  const tabRefs = useRef<(HTMLLIElement | null)[]>([]);

  const onTabRef = useCallback((elt: HTMLLIElement | null, index: number) => {
    tabRefs.current[index] = elt;
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      if (scrollContainer) {
        scrollContainer.scrollLeft += e.deltaY;
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const onClickTab = useCallback((section: string) => {
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const tab = tabRefs.current[currentIntersectingElementIndex];

    if (!tab || !scrollContainer) {
      return;
    }

    scrollIntoViewIfNeeded(tab, scrollContainer);
  }, [currentIntersectingElementIndex]);

  return (
    <nav className="PVScrollSpyTabBar">
      <ul className="PVScrollSpyTabBar__List" ref={scrollContainerRef}>
        {Object.entries(elementIds).map(([sectionId, sectionTitle], idx) => (
          <li
            key={sectionId}
            className={cx("PVScrollSpyTabBar__List__Item", {
              "PVScrollSpyTabBar__List__Item--IsActive":
                currentIntersectingElementIndex === idx,
            })}
            onClick={() => onClickTab(sectionId)}
            ref={(ref) => onTabRef(ref, idx)}
          >
            {sectionTitle}
          </li>
        ))}
      </ul>
    </nav>
  );
};

type PVScrollSpySectionContextType = {
  id: string;
  isActive: boolean;
};

const PVScrollSpySectionContext =
  createContext<PVScrollSpySectionContextType | null>(null);

const useScrollspySectionContext = () => {
  const context = useContext(PVScrollSpySectionContext);
  if (!context) {
    throw new Error(
      "PVScrollSpySection should be rendered inside a PVScrollSpySectionProvider"
    );
  }
  return context;
};

type PVScrollSpySectionProviderProps = {
  children: ReactNode;
  id: string;
  isActive: boolean;
};

const PVScrollSpySectionProvider: FC<PVScrollSpySectionProviderProps> = ({
  children,
  id,
  isActive,
}) => {
  return (
    <PVScrollSpySectionContext.Provider value={{ id, isActive }}>
      {children}
    </PVScrollSpySectionContext.Provider>
  );
};

const makeSectionTitleId = (title: string) => {
  return slugify(title, { lower: true });
};

type PVScrollSpySectionsProps = {
  className?: string;
  children?: ReactNode;
};

// Wraps all the different sections, calculates IDs, shoves them into context.
export const PVScrollSpySections: FC<PVScrollSpySectionsProps> = ({
  className,
  children,
}) => {
  const { setElements, setElementIds, currentIntersectingElementIndex } =
    useScrollspyContext();

  useEffect(() => {
    const sectionElts = document.querySelectorAll(`.${SECTION_CLASSNAME}`);
    setElements(Array.from(sectionElts));

    const collectedElementIds: Record<string, string> = {};
    Children.forEach(children, (child) => {
      if (!isValidElement<PVScrollSpySectionWrapperProps>(child)) {
        return null;
      }

      const slug = makeSectionTitleId(child.props.title);
      collectedElementIds[slug] = child.props.title;
    });

    setElementIds(collectedElementIds);
  }, [children, setElements, setElementIds]);

  const childrenWithProvider = useMemo(() => {
    return Children.map(children, (child, index) => {
      if (!isValidElement<PVScrollSpySectionWrapperProps>(child)) {
        return null;
      }

      const id = makeSectionTitleId(child.props.title);
      const isActive = index === currentIntersectingElementIndex;

      return (
        <PVScrollSpySectionProvider id={id} isActive={isActive}>
          {child}
        </PVScrollSpySectionProvider>
      );
    });
  }, [children, currentIntersectingElementIndex]);

  return <div className={className}>{childrenWithProvider}</div>;
};

type PVScrollSpySectionWrapperProps = {
  /** We don't use this title directly in the element, but we need it to auto
   * generate IDs. */
  title: string;
} & HTMLProps<HTMLElement>;

export const PVScrollSpySectionWrapper: FC<PVScrollSpySectionWrapperProps> = ({
  className,
  children,
}) => {
  const { offsetPx } = useScrollspyContext();
  const { id, isActive } = useScrollspySectionContext();

  const appliedClasses = cx(
    className,
    "PVScrollSpySectionWrapper",
    SECTION_CLASSNAME,
    {
      "PVScrollSpySectionWrapper--IsActive": isActive,
    }
  );

  return (
    <section
      id={id}
      className={appliedClasses}
      style={{ scrollMarginTop: `${offsetPx}px` }}
    >
      {children}
    </section>
  );
};

type PVScrollSpySectionProps = {
  title: string;
} & PropsWithChildren;

export const PVScrollSpySection: FC<PVScrollSpySectionProps> = ({
  title,
  children,
}) => {
  return (
    <PVScrollSpySectionWrapper title={title} className="PVScrollSpySection">
      <PVSectionHeading className="PVScrollSpySection__Title">
        {title}
      </PVSectionHeading>
      <div className="PVScrollSpySection__Content">{children}</div>
    </PVScrollSpySectionWrapper>
  );
};

type PVScrollSpyFormSectionProps = {
  title: string;
  renderActions?(): ReactElement;
} & ComponentProps<"form">;

export const PVScrollSpyFormSection: FC<PVScrollSpyFormSectionProps> = ({
  title,
  children,
  renderActions,
  ...formProps
}) => {
  return (
    <PVScrollSpySectionWrapper title={title} className="PVScrollSpyFormSection">
      <form className="PVScrollSpyFormSection__Form" {...formProps}>
        <div className="PVScrollSpyFormSection__Form__Header">
          <PVSectionHeading className="PVScrollSpyFormSection__Form__Header__Title">
            {title}
          </PVSectionHeading>
          {renderActions && (
            <div className="PVScrollSpyFormSection__Form__Header__Actions">
              {renderActions()}
            </div>
          )}
        </div>
        <div className="PVScrollSpyFormSection__Form__Content">{children}</div>
      </form>
    </PVScrollSpySectionWrapper>
  );
};

/**
 * Finds the index of the first fully visible child element within a scrolling
 * container.
 */
const getFirstVisibleChildIndex = (
  containerEl: Element,
  childEls: Element[],
  topOffset = 0
): number => {
  if (!containerEl || !childEls.length) {
    return -1;
  }

  // Check if scrolled to top.
  const isScrolledToTop = containerEl.scrollTop <= 0;

  if (isScrolledToTop) {
    return 0;
  }

  // Check if scrolled to bottom.
  const isScrolledToBottom =
    Math.abs(
      containerEl.scrollHeight -
        containerEl.scrollTop -
        containerEl.clientHeight
    ) <= 10;

  if (isScrolledToBottom) {
    return childEls.length - 1;
  }

  const containerRect = containerEl.getBoundingClientRect();
  const containerTop = containerRect.top + topOffset;
  const containerBottom = containerRect.bottom;

  const sortedIndexes = childEls
    .map((el, index) => ({ el, index }))
    .sort((a, b) => {
      const aRect = a.el.getBoundingClientRect();
      const bRect = b.el.getBoundingClientRect();
      return aRect.top - bRect.top;
    });

  for (const { el, index } of sortedIndexes) {
    const childRect = el.getBoundingClientRect();

    if (childRect.bottom < containerTop) {
      continue;
    }

    if (childRect.top > containerBottom) {
      break;
    }

    if (childRect.top >= containerTop && childRect.bottom <= containerBottom) {
      return index;
    }
  }

  return -1;
};

const scrollIntoViewIfNeeded = (
  element: HTMLElement,
  container: HTMLElement
) => {
  const margin = 8;

  const isFullyVisible =
    element.offsetLeft >= container.scrollLeft + margin &&
    element.offsetLeft + element.offsetWidth <=
      container.scrollLeft + container.clientWidth - margin;

  if (!isFullyVisible) {
    const elementLeft = element.offsetLeft - margin;
    container.scrollTo({
      left: elementLeft,
      behavior: "smooth",
    });
  }
};
