import { useEffect, useState } from "react";
import { ActivityItemType } from "ts-types/ApiTypes";

export const useSubmissionHistory = ({ notes, submissionHistory }) => {
  const [mergedHistory, setMergedHistory] = useState<ActivityItemType[]>([]);

  useEffect(() => {
    if (!notes && !submissionHistory) {
      setMergedHistory([]);
      return;
    }

    const convertNoteToHistoryItem = (note: Record<string, unknown>) => ({
      actor_id: note.user,
      actor_username: note.actor_username,
      timestamp: note.created_time,
      verb: "added",
      messages: [{ field: "note", to_value: note.text }],
    });

    const notesAsHistory = notes?.map(convertNoteToHistoryItem) || [];
    const history = submissionHistory || [];

    const merged = [...notesAsHistory, ...history].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    setMergedHistory(merged);
  }, [notes, submissionHistory]);

  return mergedHistory;
};
