import { FC, HTMLProps } from "react";

import { PVSectionSubHeading } from "features/submission-dashboard/PVHeadings";

import "./PVFormsLayouts.scss";

type PVInputsColumnProps = {
  title: string;
} & HTMLProps<HTMLDivElement>;

const PVInputsColumn: FC<PVInputsColumnProps> = ({
  title,
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className="PVInputsColumn">
      <PVSectionSubHeading className="PVInputsColumn__Title">
        {title}
      </PVSectionSubHeading>
      {children}
    </div>
  );
};

type PVInputsColumnsGridProps = HTMLProps<HTMLDivElement>;

const PVInputsColumnsGrid: FC<PVInputsColumnsGridProps> = ({
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className="PVInputsColumnsGrid">
      {children}
    </div>
  );
};

export { PVInputsColumn, PVInputsColumnsGrid };
