import { FC, useCallback, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useHotkeys } from "react-hotkeys-hook";

import {
  PingSearchQueryBuilder,
  useSearchQueryBuilder,
} from "@repo/ping-react-js";

import { PingVisionSubmissionListItem } from "features/submission-dashboard/PingVisionSubmissionListItem";
import {
  updateMultiSelectToast,
  clearMultiSelectToast,
} from "features/submission-dashboard/PingMultiSelectOptions";
import { useAppDispatch, useAppSelector } from "utils/redux";
import { SovDataType } from "ts-types/DataTypes";
import { FRONT_END_BASE_URL } from "constants/index";
import { useSlug, useTeamId } from "utils/hooks";
import {
  setIsCommandMenuOpened,
  setSelectedSubmissions,
} from "reducers/settings";
import { previewEmpty, setSlug, setTeamId } from "reducers/inbox";
import {
  SEARCH_PARAM_NAME,
  useGetAdvancedSearchFields,
} from "features/submission-dashboard/queries";

import "./PingVisionSubmissionList.scss";

type PingVisionSubmissionListProps = {
  sovs: SovDataType[];
  selectedItemId: string | null;
};

export const PingVisionSubmissionList: FC<PingVisionSubmissionListProps> = ({
  sovs,
  selectedItemId,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [cleared, setCleared] = useState<number>(0);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  const slug = useSlug();
  const teamId = useTeamId();

  useEffect(() => {
    if (slug) {
      dispatch(setSlug(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (teamId) {
      dispatch(setTeamId(teamId));
    }
  }, [dispatch, teamId]);

  const documentPreview = useAppSelector(
    (state) => state.inbox.documentPreview
  );

  useEffect(() => {
    if (selectedItems.size) {
      dispatch(setSelectedSubmissions([...selectedItems]));
      updateMultiSelectToast(
        selectedItems,
        () => {
          setSelectedItems(new Set());
          setCleared(Math.random());
        },
        () => {
          dispatch(setIsCommandMenuOpened(true));
        },
        () => {}
      );
    } else {
      clearMultiSelectToast();
      dispatch(setSelectedSubmissions([]));
    }
  }, [selectedItems, dispatch]);

  const onClickIncomingItem = useCallback(
    (id: string) => {
      if (!teamId && !slug) {
        return;
      }

      const currentSearchParams = new URLSearchParams(window.location.search);
      const newUrl = `${FRONT_END_BASE_URL}/i/${id}`;
      const searchString = currentSearchParams.toString();
      const finalUrl = searchString ? `${newUrl}?${searchString}` : newUrl;

      history.push(finalUrl);
      if (documentPreview) {
        dispatch(previewEmpty());
      }
    },
    [teamId, history, slug, dispatch, documentPreview]
  );
  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      setSelectedItems((prevSelected) => {
        const newSelected = new Set(prevSelected);

        if (isShiftDown && lastSelectedItem && checked) {
          const startIndex = sovs.findIndex(
            (sov) => sov.id === lastSelectedItem
          );
          const endIndex = sovs.findIndex((sov) => sov.id === id);
          const [lower, upper] = [startIndex, endIndex].sort((a, b) => a - b);

          for (let i = lower; i <= upper; i++) {
            newSelected.add(sovs[i].id);
          }
        } else {
          if (checked) {
            newSelected.add(id);
          } else {
            newSelected.delete(id);
          }
        }

        setLastSelectedItem(checked ? id : null);
        return newSelected;
      });
    },
    [isShiftDown, lastSelectedItem, sovs]
  );

  const currentIndex = sovs?.findIndex((s) => s.id === selectedItemId);

  const selectNextSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex < sovs.length - 1) {
      const nextSov = sovs[currentIndex + 1];
      onClickIncomingItem(nextSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  const selectPreviousSov = useCallback(() => {
    if (currentIndex !== undefined && currentIndex > 0) {
      const previousSov = sovs[currentIndex - 1];
      onClickIncomingItem(previousSov.id);
    }
  }, [currentIndex, sovs, onClickIncomingItem]);

  useHotkeys("down", selectNextSov, [selectNextSov]);
  useHotkeys("up", selectPreviousSov, [selectPreviousSov]);
  useHotkeys("esc", () => {
    setSelectedItems(new Set());
  });

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(true);
    },
    { keyup: false, enableOnFormTags: ["input", "select", "textarea"] }
  );

  useHotkeys(
    "shift",
    () => {
      setIsShiftDown(false);
    },
    { keyup: true, enableOnFormTags: ["input", "select", "textarea"] }
  );

  const { advancedSearchFields: fields } = useGetAdvancedSearchFields();

  const { searchValues, setSearchValues, clearSearchValues } =
    useSearchQueryBuilder(fields, history, SEARCH_PARAM_NAME);

  const onSearch = useCallback(
    (data: Record<string, string> | null) => {
      if (data === null && isEmpty(searchValues)) {
        return;
      }

      // If there's nothing in the form data, we should clear all filters.
      if (!data) {
        clearSearchValues();
        return;
      }

      setSearchValues(data);
    },
    [clearSearchValues, setSearchValues, searchValues]
  );

  const sovList = useMemo(() => {
    return sovs.map((sov) => {
      const key = `sov-${sov.id}-${selectedItems.has(sov.id)}`;
      return (
        <PingVisionSubmissionListItem
          itemIsChecked={selectedItems.has(sov.id)}
          key={key}
          sov={sov}
          isSelected={selectedItemId === sov.id}
          onClickIncomingItem={onClickIncomingItem}
          onCheckboxChange={handleCheckboxChange}
        />
      );
    });
  }, [
    sovs,
    selectedItems,
    selectedItemId,
    onClickIncomingItem,
    handleCheckboxChange,
  ]);

  return (
    <div className="PingVisionSubmissionList">
      <div className="PingVisionSubmissionList__Search">
        <PingSearchQueryBuilder
          fields={fields}
          values={searchValues}
          onChange={onSearch}
        />
      </div>
      <ul className="PingVisionSubmissionList__List" key={cleared}>
        {sovList}
      </ul>
    </div>
  );
};
