import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useWindowSize } from "@uidotdev/usehooks";

import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
  RealTimeUpdates,
  useAuth,
  refreshToast,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PingVisionSubmissionDetails } from "features/submission-dashboard/PingVisionSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { PVDocumentPreviewPanel } from "features/submission-dashboard/PVDocumentPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import { useGetSubmissionList } from "features/submission-dashboard/queries";
import {
  useGetSubmissionHistoryQuery,
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { useAppSelector } from "utils/redux";
import { useSlug, usePingId } from "utils/hooks";
import { setSubmissionHistory } from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionDashboard.scss";

const AUTO_FOCUS_MODE_SCREEN_WIDTH_PX = 1440;

const PingVisionSubmissionDashboard = () => {
  const dispatch = useDispatch();
  const pingId = usePingId();

  const { accessToken } = useAuth();
  const [sovs, setSovs] = useState<SovDataType[]>([]);

  const documentPreview = useAppSelector(
    (state) => state.inbox.documentPreview
  );

  const slug = useSlug();

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened
  );

  const envData = useAppSelector((state) => state.settings.envData);

  const selectedItem = useMemo(
    () => sovs.find((sov) => sov.id === pingId),
    [sovs, pingId]
  );

  const { data: sovData } = useGetSubmissionList();

  useGetSettingsQuery({});

  useGetNavQuery({});

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryQuery(
    { id: pingId || "" },
    { skip: !pingId }
  );

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  useEffect(() => {
    setSovs(sovData?.results || []);
  }, [sovData]);

  const [isFocusModeEnabled, setIsFocusModeEnabled] = useState(false);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (
      documentPreview &&
      windowSize.width &&
      windowSize.width <= AUTO_FOCUS_MODE_SCREEN_WIDTH_PX
    ) {
      setIsFocusModeEnabled(true);
    } else {
      setIsFocusModeEnabled(false);
    }
  }, [windowSize, documentPreview]);

  return (
    <>
      <PingCommandMenuModal
        isOpen={isCommandMenuOpened}
        onClose={() => {
          dispatch(setIsCommandMenuOpened(false));
        }}
      />
      {accessToken && envData?.ENABLE_CHANNELS && (
        <RealTimeUpdates
          accessToken={accessToken}
          baseUrl={import.meta.env.VITE_APP_WEBSOCKETS_CHANNEL}
          onUpdate={(data) => {
            if (data.type === "submission.update" && data.data.id === pingId) {
              refreshToast("Submission is out of date.");
            }
          }}
        />
      )}
      <BaseLayout
        title="Ping Radar [beta]"
        shouldShowPoweredByPing={false}
        className="PingVisionSubmissionDashboard"
      >
        <MainContent
          hasTopPadding={false}
          paddingSize="slim"
          className="PingVisionSubmissionDashboard__Main"
        >
          <PanelGroup direction="horizontal" autoSaveId="submission-dashboard">
            {!isFocusModeEnabled && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-sidebar"
                  order={1}
                  minSize={10}
                  maxSize={15}
                >
                  <PVPanel>
                    <PingVisionSidebar />
                  </PVPanel>
                </Panel>
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              </>
            )}
            {slug && !isFocusModeEnabled && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-submission-list"
                  order={2}
                  minSize={10}
                  maxSize={30}
                >
                  <PVPanel>
                    <PingVisionSubmissionList
                      sovs={sovs}
                      selectedItemId={pingId}
                    />
                  </PVPanel>
                </Panel>
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              </>
            )}
            <Panel
              className="PingVisionSubmissionDashboard__Main__Panel"
              id="submission-dashboard-submission-details"
              order={3}
              minSize={25}
              maxSize={70}
            >
              {selectedItem ? (
                <PVPanel>
                  <PingVisionSubmissionDetails selectedItem={selectedItem} />
                </PVPanel>
              ) : (
                <PVPanel>
                  <PVEmptyPanelMessage message="Select a submission to view details" />
                </PVPanel>
              )}
            </Panel>
            <PingPanelResizeHandle orientation="horizontal" width="slim" />

            {documentPreview && selectedItem && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-preview-panel"
                  order={4}
                  minSize={25}
                  maxSize={70}
                >
                  <PVPanel>
                    <PVDocumentPreviewPanel sovid={selectedItem.id} />
                  </PVPanel>
                </Panel>
              </>
            )}
          </PanelGroup>
        </MainContent>
      </BaseLayout>
    </>
  );
};

export default PingVisionSubmissionDashboard;
