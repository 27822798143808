import { FC, useCallback, useEffect, useState } from "react";

import { PingFloatingSelectInput, US_STATES } from "@repo/ping-react-js";

import { PVLabelValueEditor } from "features/submission-dashboard/PVLabelValueEditor";
import { PVLabelValueButton } from "features/submission-dashboard/PVLabelValueButton";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";
import { SovDataType } from "ts-types/DataTypes";

import "./PVBrokerInformationGrid.scss";

type PVBrokerInformationGridProps = {
  selectedItem: SovDataType;
};

export const PVBrokerInformationGrid: FC<PVBrokerInformationGridProps> = ({
  selectedItem,
}) => {
  const [selectedStateIndex, setSelectedStateIndex] = useState<number | null>(
    null
  );

  useEffect(() => {
    setSelectedStateIndex(
      US_STATES.findIndex(
        (option) => option.label === selectedItem.broker_state
      )
    );
  }, [selectedItem]);

  const [updateSubmission] = useUpdateSubmissionTriageMutation();

  const onBlur = useCallback(
    (fieldName: string, newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          [fieldName]: newContent,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  const onChangeState = useCallback(
    (index: number) => {
      setSelectedStateIndex(index);
      updateSubmission({
        id: selectedItem.id,
        data: {
          broker_state: US_STATES[index].label,
        },
      });
    },
    [updateSubmission, selectedItem]
  );

  return (
    <div className="PVBrokerInformationGrid">
      <PVLabelValueEditor
        label="Name"
        value={selectedItem.broker_name || "Unknown Name"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_name", newContent)}
      />

      <PVLabelValueEditor
        label="Email"
        value={selectedItem.broker_email || "Unknown Email"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_email", newContent)}
      />

      <PVLabelValueEditor
        label="Street"
        value={selectedItem.broker_street || "Unknown Street"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_street", newContent)}
      />

      <PVLabelValueEditor
        label="Street 2"
        value={selectedItem.broker_street2 || "Unknown Street 2"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_street2", newContent)}
      />

      <PVLabelValueEditor
        label="City"
        value={selectedItem.broker_city || "Unknown City"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_city", newContent)}
      />

      <PingFloatingSelectInput
        options={US_STATES}
        selectedIndex={selectedStateIndex}
        setSelectedIndex={onChangeState}
        placement="bottom-end"
        renderLabelElt={(selectedItemLabel, isOpen) => {
          return (
            <PVLabelValueButton
              label="State"
              value={selectedItemLabel || "Unknown State"}
              isActive={isOpen}
            />
          );
        }}
      />

      <PVLabelValueEditor
        label="ZIP"
        value={selectedItem.broker_zip || "Unknown ZIP"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_zip", newContent)}
      />

      <PVLabelValueEditor
        label="Country"
        value={selectedItem.broker_country || "Unknown Country"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_country", newContent)}
      />

      <PVLabelValueEditor
        label="FEIN"
        value={selectedItem.broker_fein || "Unknown FEIN"}
        isEditable={true}
        onChange={(newContent) => onBlur("broker_fein", newContent)}
      />
    </div>
  );
};
